body{
  box-sizing: border-box;
  padding: 0;
  Margin: 0;
  height: 100%;
  }

  #LandingPG{
    background: url(".");
  }
 

@tailwind base;
@tailwind components;
@tailwind utilities;